
































// @ is an alias to /src
import LoginBar from '@/components/LoginBar.vue'
import {Component, Vue} from 'vue-property-decorator';
import {AuthBackendAPI} from '@/logic/AuthBackendAPI';

@Component({
    components: {LoginBar}
})
export default class App extends Vue {
    created(): void {
        this.$store.dispatch('updateUser')
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }
}
