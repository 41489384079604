import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    { path: '/', redirect: '/status' },
    {
        path: '/patrons',
        name: 'Patrons',
        component: () => import('../views/Patrons.vue')
    },
    {
        path: '/status',
        name: 'Status',
        component: () => import('../views/MyPatronStatus.vue')
    },
    {
        path: '/whitelist',
        name: 'Whitelist',
        component: () => import('../views/Whitelist.vue')
    },
    {
        path: '/status/:id',
        name: 'PledgeHistory',
        props: true,
        component: () => import('../views/PatronStatusById.vue')
    },
    {
        path: '/report/:year/:month',
        name: 'PatronReport',
        props: true,
        component: () => import('../views/PatronReport.vue')
    },
    {
        path: '/report',
        redirect: { name: 'PatronReport', params: { month: String(new Date().getMonth() + 1), year: String(new Date().getFullYear()) }},
        name: 'PatronReportCurrentMonth',
        props: true,
        component: () => import('../views/PatronReport.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
