import Vue from 'vue'
import Vuex from 'vuex'
import {AuthBackendAPI, SessionDto} from '@/logic/AuthBackendAPI';

Vue.use(Vuex)

export interface UserState {
  error: boolean;
  loaded: boolean;
  user: SessionDto;
}

export default new Vuex.Store({
  state: {
    currentUserState: {
      error: false,
      loaded: false,
      user: null
    }
  },
  mutations: {
    setUserState(state, userState) {
      state.currentUserState = userState;
    }
  },
  actions: {
    async updateUser(context) {
      try {
        const user = await AuthBackendAPI.getCurrentUser();
        context.commit('setUserState', {
          error: false,
          loaded: true,
          user
        });
      } catch(ex) {
        context.commit('setUserState', {
          error: true,
          loaded: true,
          user: null
        })
      }
    }
  },
  modules: {
  }
})
