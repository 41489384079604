import Axios from "axios";
import {UserState} from '@/store';

export interface DiscordUserDto {
    username: string;
    discriminator: string;
    id: string;
}

export interface DiscordMemberDto {
    user: DiscordUserDto;
    nick: string;
    roles: string[];
}

export interface DiscordMembersDto {
    members: DiscordMemberDto[];
}

export interface SessionDto {
    discordGuildMember: DiscordMemberDto;
    minecraftId: string;
    asaId: string;
}

export abstract class AuthBackendAPI {
    private static authBackendAPI = Axios.create();

    public static isAdmin(user: UserState): boolean {
        const roles = user?.user?.discordGuildMember?.roles;
        if(!roles) {
            return false;
        }

        return roles.indexOf('264115281897455616') >= 0;
    }

    static async getAllDiscordMembers(): Promise<DiscordMemberDto[]>{
        const url = 'https://auth.domination-gaming.com/discord/members';
        const response = await this.authBackendAPI.get<DiscordMembersDto>(url, {withCredentials: true});
        return response.data.members;
    }

    static async getCurrentUser(): Promise<SessionDto>{
        const url = 'https://auth.domination-gaming.com/user';
        const response = await this.authBackendAPI.get<SessionDto>(url, {withCredentials: true});
        return response.data;
    }

    static async getDiscordMember(discordId: string): Promise<DiscordMemberDto> {
        const url = `https://auth.domination-gaming.com/discord/members/${encodeURI(discordId)}`;
        const response = await this.authBackendAPI.get<DiscordMemberDto>(url, {withCredentials: true});
        return response.data;
    }

    static async linkMinecraftAccount(minecraftUsername: string): Promise<SessionDto> {
        const url = `https://auth.domination-gaming.com/user/linkMinecraftAccount`;
        const response = await this.authBackendAPI.post<SessionDto>(url, { minecraftUsername }, {withCredentials: true});
        return response.data;
    }

    static async linkAsaAccount(asaId: string): Promise<SessionDto> {
        const url = `https://auth.domination-gaming.com/user/linkAsaAccount`;
        const response = await this.authBackendAPI.post<SessionDto>(url, { asaId }, {withCredentials: true});
        return response.data;
    }
}
