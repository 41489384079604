


















import {Component, Vue} from 'vue-property-decorator';
import {UserState} from '@/store';

@Component
    export default class LoginBar extends Vue {

        getAuthenticationLink(): string {
            return "https://auth.domination-gaming.com/oauth/discord?redirect_uri=" + encodeURIComponent(window.location.href);
        }

        getLogoutLink(): string {
            return "https://auth.domination-gaming.com/user/logout?redirect_uri=" + encodeURIComponent(window.location.href);
        }

        currentUser(): UserState {
            return this.$store.state.currentUserState;
        }

    }
